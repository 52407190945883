import { ApiService } from "../api/ApiService";

export interface Service {
  id: string;
  name: string;
  port: number;
  protocol: string;
}

export interface Domain {
  id: string;
  fqdn: string;
  services: Service[];
  createdAt: string;
}

export interface ListDomainsResponse {
  domains: Domain[];
  domainsCount: number;
  servicesCount: number;
}

export interface ListDomainsRequest {
  limit?: number;
  skip?: number;
}

export class DomainService {
  static async listDomains({
    limit = 100,
    skip = 0,
  }: ListDomainsRequest): Promise<ListDomainsResponse> {
    const response = await ApiService.get<ListDomainsResponse>(
      "/v1/domains?limit=" + limit + "&skip=" + skip
    );
    return response;
  }
}
